<template>
    <div>
        <h2>Entrar</h2>
        <Loading :active="loading" />

        <div class="list-group">
            <div class="content-input-n">
                <input class="input-n" type="text" v-model="user" placeholder="Usuário" />
            </div>
            <div class="content-input-n">
                <input class="input-n" type="password" v-model="pass" placeholder="Senha" />
            </div>
        </div>
        <div class="content-btn">
            <button class="btn front-btn btn-size" @click="getLogin" :disabled="loading">Entrar</button>
        </div>
    </div>
</template>

<script>
import Loading from '../components/Loading'
import axios from 'axios'
import { Device } from '@capacitor/device'

export default {
    components: { Loading },

    data: () => ({
        loading: false,
        dados: [],
        user: '',
        pass: ''
    }),

    methods: {
        async getLogin() {
            this.loading = true
            const info = await Device.getInfo()
            const id = await Device.getId()
            const dados = await axios.post(`${this.$url}/canais`, {
                user: this.user,
                pass: this.pass,
                device: id.uuid,
                osVersion: info.osVersion,
                model: info.model
            }).catch(() => {
                //alert('Aconteceu um erro!')
                this.loading = false
            })
            if (dados.data) {
                localStorage.setItem('_l', JSON.stringify(dados.data))
                this.dados = dados.data
                this.user = ''
                this.pass = ''
                this.$router.push(`/coordsadmin/${this.dados.title}`)
            }
            this.loading = false
        },
        getRota(rota) {
            this.$router.push(`/coords/${rota}`)
        },
        async logDeviceInfo() {
            const info = await Device.getInfo()
            const id = await Device.getId()
            console.log(info, id)
        }
    },
    mounted() {
        //this.loading = true
        //this.getDados()
        this.logDeviceInfo()
        if (localStorage.getItem('_l')) {
            setTimeout(() => {
                const l = JSON.parse(localStorage.getItem('_l'))
                this.$router.push(`/coordsadmin/${l.title}`)
            }, 300)
        }
    },

}
</script>

<style lang="scss" scoped>
.content-btn {
    display: flex;
    justify-content: center;
}

h2 {
    display: flex;
    justify-content: space-between;
    color: #673bb7;
    margin: 10px 0;
}

.btn-size {
    margin-top: 20px;
    width: 320px;
    height: 30px;
}

.input-n {
    border: 1px solid #673ab7;
    height: 30px;
    border-radius: 5px;
    width: 270px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 5px 10px;
}

.btn {
    display: flex;
    height: 50px;
    width: 80%;
    align-items: center;
    justify-content: center;
}

.btn-content {
    margin: 0 auto;
}

.coord-content {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;

    .coord-texts {
        flex: 2;
        display: flex;
        align-items: center;

        .city {
            color: #2c3e50;
            font-size: 12px;
            text-align: center;
            font-weight: bold;
            width: 85px;
        }

        .coord-text {
            color: #2c3e50;
            font-size: 14px;
        }
    }

    .coord-btn {
        flex: 1;

        a {
            display: flex;
            width: 80px;
            color: #fff;
            background-color: #673ab7;
            text-decoration: none;
            padding: 10px 15px;
        }
    }
}

.front-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #673ab7;
}
</style>